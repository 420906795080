import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import AppBar from './app-bar';
import { getTranslation } from './translations';

const Market = () => {
  const [theme, setTheme] = useState('theme-dark');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [currentImage, setCurrentImage] = useState('market.jpg');
  const lastScrollTop = useRef(0);
  const navigate = useNavigate();
  const [currentLanguage, setCurrentLanguage] = useState('en');

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.BackButton.show();

      window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/');
      });

      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }

    const intervalId = setInterval(() => {
      setCurrentImage(prev => prev === 'market.jpg' ? 'market2.jpg' : 'market.jpg');
    }, 5000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleHapticFeedback = () => {
    if (window.Telegram?.WebApp?.HapticFeedback) {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
    }
  };

  const openTelegramMiniApp = () => {
    handleHapticFeedback();
    window.location.href = 'https://t.me/spacebasenftbot/market';
  };

  const toggleTheme = () => {
    handleHapticFeedback();
    setTheme(theme === 'theme-light' ? 'theme-dark' : 'theme-light');
  };

  const openConfirmation = (item) => {
    handleHapticFeedback();
    setSelectedItem(item);
    setShowConfirmation(true);
  };

  const closeConfirmation = () => {
    handleHapticFeedback();
    setShowConfirmation(false);
    setSelectedItem(null);
  };

  const goToMansion = () => {
    handleHapticFeedback();
    navigate('/mansion');
  };

  const goToFootballClub = () => {
    handleHapticFeedback();
    navigate('/footballclub');
  };

  const goToTesla = () => {
    handleHapticFeedback();
    navigate('/TeslaUpgrades');
  };

  useEffect(() => {
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop.current) {
        setIsHeaderVisible(false);
      } else if (st < lastScrollTop.current) {
        setIsHeaderVisible(true);
      }
      lastScrollTop.current = st <= 0 ? 0 : st;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={theme}>
      <div id="page">
        <div className="divider mt-4"></div>
        
        <div className="page-c0ntent">
          <div className="card card-style bg-1" data-card-height="450" style={{
            height: '450px', 
            backgroundImage: `url("/images1/${currentImage}")`,
            transition: 'background-image 1s ease-in-out'
          }}>
            <div className="card-top">
              <h1 className="text-center color-white font-30 font-800 mt-4 mb-0"></h1>
              <p className="color-white text-center font-22"></p>
            </div>
            <div className="card-bottom mb-1">
              <button 
                onClick={openTelegramMiniApp}
                className="btn btn-m btn-center-l rounded-sm bg-red-dark mb-3 font-700 scale-box"
              >
                {currentImage === 'market.jpg' ? 'Get Started' : 'Explore More'}
              </button>
            </div>
            <div className="card-overlay bg-gradient opacity-70"></div>
          </div>

          <div className="c0ntent my-0">
            <div className="divider mt-4"></div>
          </div>

          <div className="c0ntent">
            <div className="d-flex">
              <div className="align-self-center">
                <h1 className="mb-0 font-18">{getTranslation(currentLanguage, 'categories')}</h1>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col-6 pe-0">
                <div className="card card-style mx-0" style={{backgroundImage: 'url("/images1/mansion/main.jpg")', minHeight: '350px'}} onClick={goToMansion}>
                  <div className="card-bottom p-3">
                    <h2 className="color-white">{getTranslation(currentLanguage, 'mansion')}</h2>
                    <p1 className="color-white opacity-60 line-height-s">
                      {getTranslation(currentLanguage, 'mansionDescription')}
                    </p1>
                  </div>
                  <div className="card-overlay bg-gradient opacity-30"></div>
                  <div className="card-overlay bg-gradient"></div>
                </div>
              </div>
              <div className="col-6">
                <div className="card card-style mx-0 mb-2" style={{backgroundImage: 'url("/images1/football/main.jpg")', minHeight: '170px'}} onClick={goToFootballClub}>
                  <div className="card-bottom p-3">
                    <h2 className="color-white">{getTranslation(currentLanguage, 'footballClub')}</h2>
                    <p1 className="color-white opacity-60 line-height-s font-12">
                      {getTranslation(currentLanguage, 'footballClubDescription')}
                    </p1>
                  </div>
                  <div className="card-overlay bg-gradient opacity-30"></div>
                  <div className="card-overlay bg-gradient"></div>
                </div>
                <div className="card card-style mx-0 mb-2" style={{backgroundImage: 'url("/images1/tesla/main.jpg")', minHeight: '170px'}} onClick={goToTesla}>
                  <div className="card-bottom p-3">
                    <h2 className="color-white">{getTranslation(currentLanguage, 'buildYourTesla')}</h2>
                    <p1 className="color-white opacity-60 line-height-s font-12">
                      {getTranslation(currentLanguage, 'buildYourTeslaDescription')}
                    </p1>
                  </div>
                  <div className="card-overlay bg-gradient opacity-30"></div>
                  <div className="card-overlay bg-gradient"></div>
                </div>
              </div>
              <div className="divider mt-4"></div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <div 
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            background: '#21252a',
            padding: '20px',
            boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
            display: 'block',
            height: '320px'
          }}
        >
          <h1 className="text-center mt-4"><i className="fa fa-3x fa-info-circle scale-box color-blue-dark shadow-xl rounded-circle"></i></h1>
          <h3 className="text-center mt-3 font-700">
            {getTranslation(currentLanguage, 'confirmOrder').replace('{item}', selectedItem)}
          </h3>
          <p1 className="boxed-text-xl opacity-70">
            {getTranslation(currentLanguage, 'orderConfirmation')}
          </p1>
          <div className="row mb-0 me-3 ms-3">
            <div className="col-6">
              <button className="btn btn-full btn-m color-red-dark border-red-dark font-600 rounded-s" onClick={closeConfirmation}>{getTranslation(currentLanguage, 'cancel')}</button>
            </div>
            <div className="col-6">
              <button className="btn btn-full btn-m color-green-dark border-green-dark font-600 rounded-s" onClick={closeConfirmation}>
                {getTranslation(currentLanguage, 'confirm')}
              </button>
            </div>
          </div>
        </div>
      )}

      <AppBar language={currentLanguage} />
    </div>
  );
};

export default Market;