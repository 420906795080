import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import InviteFriends from './friends';
import InnerBoosts from './innerboosts';
import AppBar from './app-bar'; // Import your AppBar component
// import BalanceDisplay from './BalanceDisplay'; // Import BalanceDisplay
import PassiveEarn from './PassiveEarn';
import Earn from './Earn';
import Leaderboard from './Leaderboard';
import GemMining from './GemMining'; // Добавьте эту строку
import DailyRewards from './DailyRewards';
import Tasks from './tasks';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import { UserProvider } from './UserContext';
import { useUser } from './UserContext';
import GemExchange from './gemexchange';
import { getTranslation } from './translations';
import Market from './market';
import Mansion from './mansion';
import FootballClub from './Footballclub';
import BalanceLeaders from './Balanceleaders';
import GemLeaders from './Gemleaders';
import TeslaUpgrades from './TeslaUpgrades';




import './app.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faGem, faTasks, faUsers, faRocket, faTrophy } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faGem, faTasks, faUsers, faRocket, faTrophy);



const levelMapping = {
  'Bronze': 1,
  'Silver': 2,
  'Gold': 3,
  'Platinum': 4,
  'Diamond': 5,
  'Epic': 6,
  'Legendary': 7,
  'Master': 8,
  'Grandmaster': 9,
  'Lord': 10
};






const LoadingScreen = ({ onLoadingComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          onLoadingComplete();
          return 100;
        }
        const newProgress = oldProgress + 10;
        return Math.min(newProgress, 100);
      });
    }, 200);

    return () => clearInterval(timer);
  }, [onLoadingComplete]);

  return (
    <div className="loading-screen">
      <div className="loading-content">
        <div className="user-level">
          <div className="user-level-progress">
            <div 
              className="user-level-progress-bar" 
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MainContent = ({ isFirstLoad }) => {
  const { user, setUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  

  const [balance, setBalance] = useState(0);
  const [level, setLevel] = useState('Bronze');
  const [attempts, setAttempts] = useState(10);
  const [clicksRemaining, setClicksRemaining] = useState(Infinity); // Set to Infinity
  const [userLimitAttempts, setUserLimitAttempts] = useState(0);
  const [userToken, setUserToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [earnPerTap, setEarnPerTap] = useState(1);
  const [animations, setAnimations] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const [lastClickTime, setLastClickTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [coinsToNextLevel, setCoinsToNextLevel] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState('en');


  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser && telegramUser.language_code) {
        // Если язык русский, устанавливаем 'ru', иначе 'en'
        const language = telegramUser.language_code.toLowerCase().startsWith('ru') ? 'ru' : 'en';
        setCurrentLanguage(language);
      }
    }
  }, []);
 
  

 
  const [lastUpdate, setLastUpdate] = useState(0);
  const [pendingClicks, setPendingClicks] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [passiveEarn, setPassiveEarn] = useState(0);
  const [showPassiveEarn, setShowPassiveEarn] = useState(false);
  const [hasShownPassiveEarn, setHasShownPassiveEarn] = useState(false); // Add this state
  const [buttonTransform, setButtonTransform] = useState({
    scale: 1,
    translateZ: 0,
    rotateX: 0,
    rotateY: 0
  });
  



  const [initialUserData, setInitialUserData] = useState(null);

  // Определение уровней и порогов
  const levelThresholds = {
    "Bronze": 0,
    "Silver": 5000,
    "Gold": 25000,
    "Platinum": 100000,
    "Diamond": 1000000,
    "Epic": 5000000,
    "Legendary": 10000000,
    "Master": 50000000,
    "Grandmaster": 100000000,
    "Lord": 1000000000
  };

  // Новая функция для определения уровня на основе баланса
const getLevelFromBalance = (balance) => {
  const levels = Object.entries(levelThresholds);
  for (let i = levels.length - 1; i >= 0; i--) {
    if (balance >= levels[i][1]) {
      return levels[i][0];
    }
  }
  return "Bronze"; // Базовый уровень, если баланс меньше всех порогов
};


const celebrateNewLevel = () => {
  // Запускаем конфетти
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 }
  });

  // Запускаем сильную haptic feedback
  if (window.Telegram?.WebApp?.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    // Для более сильной вибрации можно использовать несколько вызовов
    setTimeout(() => window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy'), 200);
    setTimeout(() => window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy'), 400);
  }
};


  const [numbers, setNumbers] = useState([]); // State to store animation numbers
  const numberAnimationDurationSec = 0.5; // Duration for the animation

  const coinsPerClick = 1;
  // const maxClicks = 2000;

  const formatNumber = (num) => {
    if (typeof num !== 'number') return '0';
    const roundedNum = Math.floor(num);
    if (roundedNum >= 1000000) {
      return `${Math.floor(roundedNum / 1000000)}M`;
    } else if (roundedNum >= 1000) {
      return `${Math.floor(roundedNum / 1000)}K`;
    } else {
      return roundedNum.toString();
    }
  };

 

  const coinRef = useRef(null);
  const activePointers = new Set();
  const pointerUpTimeout = 50;



  const updateButtonTransform = (event) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
  
    const rotateX = ((y - centerY) / centerY) * 10; // Максимальный угол поворота 10 градусов
    const rotateY = ((centerX - x) / centerX) * 10; // Максимальный угол поворота 10 градусов
  
    setButtonTransform({
      scale: 0.98,
      translateZ: 5,
      rotateX,
      rotateY
    });
  };
  
  const resetButtonTransform = () => {
    setButtonTransform({
      scale: 1,
      translateZ: 0,
      rotateX: 0,
      rotateY: 0
    });
  };

  


  // Функция для обработки мультитач кликов
  const handlePointerDown = (event) => {
    if (attempts > 0 && clicksRemaining > 0 && userData) {
      activePointers.add(event.pointerId);
      const earnedCoins = Math.round(earnPerTap);
      
      setBalance((prevBalance) => {
        const newBalance = Math.round(prevBalance + earnedCoins);
        
        // Проверяем, изменился ли уровень
        const newLevel = getLevelFromBalance(newBalance);
        if (newLevel !== currentLevel) {
          setCurrentLevel(newLevel);
          celebrateNewLevel();
        }
        
        return newBalance;
      });
  
      setClicksRemaining((prev) => Math.max(prev - 1, 0));
      setPendingClicks((prev) => prev + 1);
      setLastClickTime(Date.now());
  
      // Определяем, будет ли это анимация пера (25% шанс)
      const isFeather = Math.random() < 0.25;
      const featherType = isFeather ? (Math.random() < 0.5 ? 'p' : 'p2') : null;
  
      setAnimations(prevAnimations => [
        ...prevAnimations.slice(-20),
        {
          id: Date.now(),
          value: isFeather ? featherType : `+${earnedCoins}`,
          x: event.clientX,
          y: event.clientY,
          isFeather: isFeather
        },
      ]);
  
      // Базовая тактильная обратная связь для каждого клика
      if (window.Telegram?.WebApp?.HapticFeedback) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      }
    }
  };

  const handlePointerUp = (event) => {
    const pointerId = event.pointerId;
    setTimeout(() => {
      if (activePointers.has(pointerId)) {
        activePointers.delete(pointerId);
      }
    }, pointerUpTimeout);
  };

// Function to handle click on the coin


  
 
const fetchUserData = async () => {
  if (userToken) {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_user_data`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({ token: userToken }),
      });
      if (!response.ok) throw new Error(`Failed to fetch user data: ${response.status}`);
      const data = await response.json();
      if (data) {
        setUserData(data.user);
        setBalance(Math.round(data.user.balance));
        setLevel(data.user.level);
        setAttempts(data.user.daily_attempts);
        setCurrentLevel(getLevelFromBalance(data.user.balance));
        setUserLimitAttempts(data.user.limit_attempts);
        setEarnPerTap(parseFloat(data.user.earn_per_tap));
        setPassiveEarn(data.user.passive_earn);

        if (clicksRemaining === null) {
          setClicksRemaining(data.user.clicks_remaining);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  }
};


  // Get the current level threshold (the one the user has already crossed)
  const getNextLevelThreshold = () => {
    const levels = Object.keys(levelThresholds);
    const currentLevelIndex = levels.indexOf(level);
    return currentLevelIndex < levels.length - 1 ? levelThresholds[levels[currentLevelIndex + 1]] : null;
  };
  
  const getCurrentLevelThreshold = () => {
    return levelThresholds[level] || 0;
  };
  
 // Модифицированная функция calculateProgress
const calculateProgress = () => {
  const currentLevel = getLevelFromBalance(balance);
  const currentThreshold = levelThresholds[currentLevel];
  const levels = Object.keys(levelThresholds);
  const currentLevelIndex = levels.indexOf(currentLevel);
  
  if (currentLevelIndex === levels.length - 1) {
    return 100; // Максимальный уровень достигнут
  }
  
  const nextLevelKey = levels[currentLevelIndex + 1];
  const nextThreshold = levelThresholds[nextLevelKey];
  
  const totalCoinsForNextLevel = nextThreshold - currentThreshold;
  const currentProgress = balance - currentThreshold;
  
  const progressPercentage = Math.min((currentProgress / totalCoinsForNextLevel) * 100, 100);
  
  return progressPercentage;
};

  
 // Модифицированная функция calculateCoinsToNextLevel
const calculateCoinsToNextLevel = () => {
  const currentLevel = getLevelFromBalance(balance);
  const levels = Object.keys(levelThresholds);
  const currentLevelIndex = levels.indexOf(currentLevel);
  
  if (currentLevelIndex === levels.length - 1) {
    return 0; // Максимальный уровень достигнут
  }
  
  const nextLevelKey = levels[currentLevelIndex + 1];
  const nextThreshold = levelThresholds[nextLevelKey];
  
  return Math.max(0, nextThreshold - balance);
};


const [currentLevel, setCurrentLevel] = useState(null);

useEffect(() => {
  const newLevel = getLevelFromBalance(balance);
  if (currentLevel === null) {
    // Первая инициализация уровня
    setCurrentLevel(newLevel);
  } else if (newLevel !== currentLevel) {
    // Уровень изменился после первой инициализации
    setCurrentLevel(newLevel);
    celebrateNewLevel();
  }
}, [balance, currentLevel]);


  const fetchGroupData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/groups`);
      if (!response.ok) throw new Error(`Failed to fetch groups: ${response.status}`);
      const groups = await response.json();
      const userGroup = groups.find((group) => group.id === userData?.group_id) || groups[0];
      setAttempts(userGroup.attempts);
    } catch (error) {
      console.error('Error fetching group data:', error);
    }
  };

  useEffect(() => {
    // Fetch user data only *once* when the user token is available
    if (userToken) {
      fetchUserData();
    }
  }, [userToken]);

  useEffect(() => {
    fetchGroupData();
  }, [userData?.group_id]);


  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.BackButton.hide();

     

      const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
      if (telegramUser) {
        const displayName = telegramUser.first_name || telegramUser.username || telegramUser.id.toString();
        const userData = {
          displayName: displayName,
          photo_url: telegramUser.photo_url,
          id: telegramUser.id
        };
        setUser(userData); // Это сохранит данные пользователя в localStorage
        setInitialUserData(userData);

        fetch(`${process.env.REACT_APP_BACKEND_URL}/request_token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            telegramId: telegramUser.id, 
            username: telegramUser.username,
            firstName: telegramUser.first_name
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setUserToken(data.token);
              localStorage.setItem('userToken', data.token);
              localStorage.setItem('jwtToken', data.jwtToken); // Добавьте эту строку
              setUserData({
                ...userData,
                token: data.token
              });
            }
          })
          .catch((error) => console.error('Error getting user token:', error));
      }
    }
  }, [setUser, navigate]);



  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const startParam = window.Telegram.WebApp.initDataUnsafe?.start_param;
      if (startParam) {
        // Это ID приглашающего пользователя
        handleInvitation(startParam);
      }
    }
  }, []);
  
  const handleInvitation = async (inviterId) => {
    if (window.Telegram.WebApp.initDataUnsafe?.user?.id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/invite`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            invitedId: window.Telegram.WebApp.initDataUnsafe.user.id,
            inviterId: inviterId
          }),
        });
        if (!response.ok) throw new Error(`Failed to accept invitation: ${response.status}`);
        const data = await response.json();
        if (data.success) {
          console.log('Invitation accepted successfully');
          // Можно добавить здесь обновление UI или показ сообщения об успешном приглашении
        }
      } catch (error) {
        console.error('Error accepting invitation:', error);
      }
    }
  };




  useEffect(() => {
    
    const interval = setInterval(() => {
      setClicksRemaining((prev) => (prev !== null ? Math.min(prev + 1, userData?.max_clicks || 2000) : null));
    }, 1000);
    return () => clearInterval(interval);
  }, [userData?.max_clicks]);

  
  function saveCoins() {
    if (pendingClicks > 0) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_BACKEND_URL}/save_coins`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        },
        body: JSON.stringify({
          token: userToken,
          coins: pendingClicks * earnPerTap,
          count: pendingClicks,
          clicksRemaining: clicksRemaining,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error(`Network response was not ok: ${res.status}`);
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            setLevel(data.level);
          }
        })
        .catch((error) => console.error('Error saving coins:', error))
        .finally(() => setIsLoading(false));
      setPendingClicks(0);
    }
  }

  // Отправка запроса на сервер каждые 30 секунд
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const now = Date.now();
      if (now - lastClickTime >= 1000 && pendingClicks > 0) {
        saveCoins();
      }
    }, 1000);
  
    return () => clearTimeout(debounceTimer);
  }, [lastClickTime, pendingClicks]);

  // Обновление баланса при получении новых данных от сервера
  useEffect(() => {
    if (userData && userData.balance) {
      setBalance(Math.round(userData.balance));
    }
  }, [userData]);

  // Обновление попыток при получении новых данных от сервера
  useEffect(() => {
    if (userData && userData.daily_attempts) {
      setAttempts(userData.daily_attempts);
    }
  }, [userData]);

  // Обновление максимальных попыток при получении новых данных от сервера
  useEffect(() => {
    if (userData && userData.limit_attempts) {
      setUserLimitAttempts(userData.limit_attempts);
    }
  }, [userData]);

  // Обновление earnPerTap при получении новых данных от сервера
  useEffect(() => {
    if (userData && userData.earn_per_tap) {
      setEarnPerTap(parseFloat(userData.earn_per_tap));
    }
  }, [userData]);

  // Обновление уровня при получении новых данных от сервера
  useEffect(() => {
    if (userData && userData.level) {
      setLevel(userData.level);
    }
  }, [userData]);


  // Обновление баланса каждую секунду на основе пассивного дохода
  useEffect(() => {
    let accumulatedIncome = 0;
    const interval = setInterval(() => {
      if (passiveEarn > 0) {
        accumulatedIncome += passiveEarn / 3600; // Накапливаем доход каждую секунду
        if (accumulatedIncome >= 1) {
          const integerIncrease = Math.floor(accumulatedIncome);
          setBalance(prevBalance => Math.floor(prevBalance) + integerIncrease);
          accumulatedIncome -= integerIncrease; // Оставляем остаток меньше 1
        }
      }
    }, 1000); // Проверяем каждую секунду
  
    return () => clearInterval(interval);
  }, [passiveEarn]);

  

  useEffect(() => {
    if (isFirstLoad && isInitialLoad && userData && userData.passive_earn > 0) {
      const lastShownDate = localStorage.getItem('lastPassiveEarnShownDate');
      const today = new Date().toDateString();
      
      if (lastShownDate !== today) {
        setShowPassiveEarn(true);
        localStorage.setItem('lastPassiveEarnShownDate', today);
      }
      setIsInitialLoad(false);
    }
  }, [userData, isFirstLoad, isInitialLoad]);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  function calculateTimeRemaining() {
    const targetDate = new Date('2024-12-15T04:00:00.000Z'); // GMT+4 is equivalent to UTC+4
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    if (difference <= 0) {
      // Countdown finished
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  return (
    <main className={`main ${location.pathname === '/friends' ? '' : 'is-prevent-touch'}`}>
      <header className="header">
        <div className="header-left">
          <div className="user-info">
            <div className="user-info-avatar">
              <img
                src="/images/avatar.jpg"
                alt="Avatar"
              />
            </div>
            <p>{userData?.displayName || initialUserData?.displayName || 'User'} <span></span></p>
          </div>
        </div>
        <div className="header-right">
        <div className="user-tap-energy">
  <div className="icon">
    <img className="coin img-responsive is-20" src="/images/icons/energy.png" alt="Coin" />
  </div>
  <div className="countdown-container"> 
  <p>{getTranslation(currentLanguage, 'energyFree')}</p>
           
  {timeRemaining.days}{getTranslation(currentLanguage, 'days')} {timeRemaining.hours}{getTranslation(currentLanguage, 'hours')} {timeRemaining.minutes}{getTranslation(currentLanguage, 'minutes')} {timeRemaining.seconds}{getTranslation(currentLanguage, 'seconds')}
           
          </div>
</div>
        </div>
      </header>
      <div className="content is-main has-glow">
        <u1l className="user-stats" >
          <li>
            <div className="user-stats-item">
            <p className="text">{getTranslation(currentLanguage, 'earnPerTap')}</p>
              <div className="price">
                <div className="price-image">
                  <img className="coin img-responsive is-20" src="/images/icons/coin.png" alt="Coin" />
                </div>
                <div className="price-value">+{earnPerTap.toFixed(0)}</div>
              </div>
            </div>
            <div className="user-stats-item v-popper--has-tooltip">
            <p className="text">{getTranslation(currentLanguage, 'coinsToLevelUp')}</p> 
  <span>{formatNumber(calculateCoinsToNextLevel())}</span>
</div>

            <div className="user-stats-item v-popper--has-tooltip">
            <p className="text">{getTranslation(currentLanguage, 'profitPerHour')}</p> 
              <div className="user-stats-profit">
                <div className="price">
                  <div className="price-image">
                    <img className="coin img-responsive is-20" src="/images/icons/coin.png" alt="Coin" />
                  </div>
                  <div className="price-value">{formatNumber(passiveEarn)}</div>
                </div>
                <div className="icon icon-info">
                  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" />
                  </svg>
                </div>
              </div>
            </div>
          </li>
        </u1l>
        <div className="user-balance-large">
      <div className="user-balance-large-inner">
        <div className="user-balance-image">
          <img className="coin img-responsive is-60" src="/images/icons/coin.png" alt="Coin" />
        </div>
        <p>{balance.toLocaleString()}</p> 
      </div>
    </div>
       
        <div className="user-tap has-gap">
  <button
    className="user-tap-button button"
    onPointerDown={(e) => {
      handlePointerDown(e);
      updateButtonTransform(e);
    }}
    onPointerUp={(e) => {
      handlePointerUp(e);
      resetButtonTransform();
    }}
    onPointerLeave={resetButtonTransform}
    disabled={isLoading}
    ref={coinRef}
  >
  <div 
    className="user-tap-button-inner"
    style={{
      transform: `
        scale(${buttonTransform.scale})
        translateZ(${buttonTransform.translateZ}px)
        rotateX(${buttonTransform.rotateX}deg)
        rotateY(${buttonTransform.rotateY}deg)
      `,
    }}
  >
    <img 
      src="/images/coinbutton.png" 
      alt="Coin Button" 
      className="coin-button-image"
    />
  </div>
</button>
<div className="user-tap-animate" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, pointerEvents: 'none', zIndex: 9999  }}>
<AnimatePresence>
  {animations.map((animation) => (
    <motion.div
      key={animation.id}
      className={animation.isFeather ? "featherAnimation" : "clickAmount"}
      initial={{ opacity: 1, y: animation.y, x: animation.x, scale: 1, rotate: animation.isFeather ? 0 : 0 }}
      animate={{ 
        opacity: 0, 
        y: animation.y - (animation.isFeather ? 200 : 150),
        scale: animation.isFeather ? 1 : 1.5,
        rotate: animation.isFeather ? 360 : 0
      }}
      exit={{ opacity: 0 }}
      transition={{ 
        duration: animation.isFeather ? 1.5 : 0.8,
        ease: "easeOut"
      }}
      style={{ 
        position: 'absolute',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'white',
        filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.5))'
      }}
    >
      {animation.isFeather ? (
        <img src={`/images/assets/${animation.value}.png`} alt="Feather" style={{ width: '30px', height: '30px' }} />
      ) : (
        animation.value
      )}
    </motion.div>
  ))}
</AnimatePresence>
</div>
          <div className="user-tap-row">
           
            {/* <div onClick={() => navigate('/boost')} className="user-tap-boost">
              <img src="/images/icons/boost.png" alt="Boost" />
              <div>Boost</div>
            </div> */}
          </div>


          <a href="#" className="user-level" >
  <div className="user-level-info">
    <p className="user-level-info-right">
    <div>{currentLevel || 'Loading...'}</div>
      <div className="icon is-12">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="currentColor" />
        </svg>
      </div>
    </p>
    <p className="user-level-info-left">
    <span>{getTranslation(currentLanguage, 'level')} </span>
    <div>{currentLevel ? `${levelMapping[currentLevel]}/10` : getTranslation(currentLanguage, 'loading')}</div>
    </p>
  </div>
  <div className="user-level-progress">
    <div 
      className="user-level-progress-bar" 
      style={{ width: `${calculateProgress()}%` }}
    />
  </div>  
</a>


        </div>
      </div>
      <AppBar language={currentLanguage} />
      {showPassiveEarn && <PassiveEarn token={userToken} /> }
     
    </main>
    
  );
};

const isMobileTelegramApp = () => {
  console.log('Telegram object:', window.Telegram);
  console.log('WebApp object:', window.Telegram?.WebApp);
  console.log('Platform:', window.Telegram?.WebApp?.platform);
  // Проверяем, что платформа android или ios
  return window.Telegram && window.Telegram.WebApp && 
    (window.Telegram.WebApp.platform === "android" || window.Telegram.WebApp.platform === "ios");
};

const UnsupportedPlatform = () => {
  return (
    <div className="unsupported-platform">
      <h1>Wow wow Space Animals! 📱✨ To unlock the magic, open our app through the Telegram mobile app and let the adventure begin!</h1>
      <img src="/images/qr.png" alt="QR Code" className="qr-code" />
    </div>
  );
};

const App = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileTelegram, setIsMobileTelegram] = useState(null);
  const [showMainContent, setShowMainContent] = useState(false);

  // Переключатель для проверки мобильной версии Telegram
  // 1 - проверка включена, 0 - проверка выключена
  const CHECK_MOBILE_TELEGRAM = 0;

  useEffect(() => {
    console.log('App useEffect triggered');
    const checkPlatform = () => {
      if (CHECK_MOBILE_TELEGRAM) {
        const result = isMobileTelegramApp();
        console.log('Is Mobile Telegram App:', result);
        setIsMobileTelegram(result);
        if (result) {
          // Если это мобильное приложение Telegram, продолжаем показывать экран загрузки
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
      } else {
        // Если проверка выключена, считаем, что это всегда мобильное приложение Telegram
        setIsMobileTelegram(true);
        setIsLoading(true);
      }
    };

    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
    }

    // Небольшая задержка для уверенности в полной загрузке Telegram API
    setTimeout(checkPlatform, 500);
  }, []);

  const handleLoadingComplete = () => {
    setIsLoading(false);
    setShowMainContent(true);
  };

  console.log('Rendering App, isMobileTelegram:', isMobileTelegram);

  if (isMobileTelegram === null) {
    console.log('Still determining platform...');
    return <LoadingScreen />;
  }

  if (!isMobileTelegram && CHECK_MOBILE_TELEGRAM) {
    console.log('Unsupported platform detected');
    return <UnsupportedPlatform />;
  }

  console.log('Rendering main app content');
  return (
    <UserProvider>
    <BrowserRouter>
      <div id="__nuxt" data-v-app="">
        <div className="page">
          <Routes>
            <Route path="/" element={
              isLoading ? 
                <LoadingScreen onLoadingComplete={handleLoadingComplete} /> : 
                showMainContent ? <MainContent isFirstLoad={true} /> : null
            } />
           
  <Route path="/friends" element={<InviteFriends />} />
  <Route path="/boost" element={<InnerBoosts />} />
  <Route path="/tasks" element={<Tasks />} /> 
  <Route path="/DailyRewards" element={<DailyRewards />} /> 
  <Route path="/gem-mining" element={<GemMining />} />
  <Route path="/leaderboard" element={<Leaderboard />} /> {/* Новый маршрут */}
  <Route path="/gem-exchange" element={<GemExchange />} />
  <Route path="/Market" element={<Market />} />
  <Route path="/Mansion" element={<Mansion />} />
  <Route path="/Footballclub" element={<FootballClub />} />
  <Route path="/Balanceleaders" element={<BalanceLeaders />} />
  <Route path="/GemLeaders" element={<GemLeaders />} />
  <Route path="/TeslaUpgrades" element={<TeslaUpgrades />} />


  
</Routes>
        </div>
      </div>
    </BrowserRouter>
    </UserProvider>
  );
};

export default App;